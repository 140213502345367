<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
    v-bind="$attrs"
    width="920"
    class="goodInfo"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDia"
    destroy-on-close
    align-center
    @open="openDia"
  >
  <div class="zh_content">
    <div class="info">
      <div class="zh_title">产品信息</div>
      <div class="zh_goods_info dp_f">
        <div class="zh_goods_img">
          <goodImg :src="goodsInfo.img" class="zh_pic"/>
        </div>
        <div class="zh_goods">
            <div class="zh_goods_name">{{goodsInfo.productName}} <span class="zh_line">/</span> <span class="zh_unit">{{goodsInfo.unitName}}</span></div>
            <div class="zh_goods_item dp_f">
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">产品品牌：</span>
                <span class="zh_goods_text">{{goodsInfo.brandName || '--'}}</span>
              </div>
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">规格/型号：</span>
                <span class="zh_goods_text">{{goodsInfo.modelName || '--'}}</span>
              </div>
            </div>
            <div class="zh_goods_item dp_f">
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">产品条码：</span>
                <more-list :list='goodsInfo.encoded' v-if='goodsInfo.encoded'></more-list>
                <span class="zh_goods_text" v-else>--</span>
              </div>
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">合计数量/金额(元)：</span>
                <span class="zh_goods_text">{{goodsInfo.totalNum}} / <span class="unit">¥</span>{{$filters.toYuan( goodsInfo.totalPrice,'元')}}</span>
              </div>
            </div>
          <div class="zh_goods_item dp_f">
<!--            <div class="zh_goods_item_l">-->
<!--              <span class="zh_goods_title">预估销售周期：</span>-->
<!--              <span class="zh_goods_text" v-if="goodsInfo.estimatedSalesCycle">{{goodsInfo.estimatedSalesCycle}}天</span>-->
<!--              <span class="zh_goods_text" v-else>&#45;&#45;</span>-->
<!--            </div>-->
            <div class="zh_goods_item_l">
              <span class="zh_goods_title">最低库存量：</span>
              <span class="zh_goods_text">{{ goodsInfo.lowNum || '--' }}</span>
            </div>
          </div>
        </div>
      </div>
       <div class="zh_title">详细信息</div>
       <div class="tabList">
        <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
         class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{border:'none'}"
        :header-row-style="{border:'none'}"
      >
        <el-table-column
          align="center"
          label="序号"
          width="100"
        >
        <template #default='scope'>
          <div class="table_text" >{{scope.$index + 1}}</div>
        </template>
       </el-table-column>
       <el-table-column
          align="center"
          label="单价（元）"
        >
        <template #default='scope'>
          <div class="table_text2" >{{$filters.toYuan(scope.row.price,'元')}}</div>
        </template>
       </el-table-column>
       <el-table-column
          align="center"
          label="库存数量"
        >
        <template #default='scope'>
          <div class="table_text" >
            <span class="table_text">{{scope.row.inventoryQuantity}}</span>
            <span class="contBtn ml_8" @click='handleDetail(scope.row)'>查看明细</span>
          </div>
        </template>
       </el-table-column>
        </el-table>
         <div class="mainPage">
           <el-pagination
               v-model:current-page="pageInfo.pageNumber"
               layout="total, prev, pager, next , jumper"
               :total="pageInfo.total"
               v-model:page-size="pageInfo.pageSize"
               @current-change="handleCurrentChange"
           />
         </div>
       </div>
    </div>
  </div>
  </el-dialog>
  </div>
  <SlowSales v-model="showDetail" :stock-id="stockId" title="查看明细" value-string="失效日期" :goods-info="chooseGoods" alarmType="1"></SlowSales>
</template>


<script setup>
import MoreList from '@/components/moreList/moreList.vue';
import SlowSales from "@/views/inStock/inventoryCheck/components/component/slowSales";
import {reactiveAssign, tableRowClassName} from '@/utils/tool.js'
import {stockDetail, stockInfo} from '@/utils/api/inStock/inventory.js'
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import goodImg from "@components/goodImg/index.vue";

const props = defineProps({
  goodsInfo:{
    type:Object,
    default:()=>{}
  },
  stockId:{
    type: [String, Number],
    default:""
  }
})
const { goodsInfo , stockId} = toRefs(props)
const { proxy } = getCurrentInstance()
const getPageInfo = ()=>{
  return{
    pageSize: 20,
    pageNumber: 1,
    total: 0,
  }
}
const pageInfo = reactive({...getPageInfo()});
const showDetail = ref(false)
const chooseGoods = reactive({})
//请求列表接口
const getRfidList = ()=>{
  stockInfo({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    stockId:stockId.value
  }).then(res=>{
    if(res.code !== 0){
      proxy.$message.error(res.msg)
      return
    }
    let list = res.data?.list || []
    list.forEach(item => {
      item.totalPrice = proxy.$filters.toYuan(item.totalPrice,'元')
    });
    tableData.value = res.data?.list || []
    pageInfo.total = res.data?.total || 0
  })
}
//弹窗打开产品列表接口
const openDia = ()=>{
  getRfidList()
}
const getGoodsInfo = ()=>{
  return goodsInfo.value
}
const emit = defineEmits(['handleClose'])

const tableData = ref([])

const handleDetail =async (e)=>{
  reactiveAssign(chooseGoods,e)
  await getGoodsDetail(e)
}
// 获取产品详情
const getGoodsDetail = (e)=>{
  stockDetail({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    price:e.price ,
  }).then((res)=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(chooseGoods, res.data, {scattered: -1})
    showDetail.value = true
  })
}

const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getRfidList();
};

const closeDia = (done)=>{
  emit('handleClose',{})
  done()
}


</script>
<script>
import { reactive, toRefs, ref, watch,getCurrentInstance } from "vue";
import { ArrowDown } from '@element-plus/icons-vue'
import rfidHistory from '@/components/rfidList/rfidHistory.vue'
export default {
  components: {
    ArrowDown,
    rfidHistory
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content{
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_goods_info{
      border-radius: 8px;
      background-color: #FAFBFC;
      padding: 24px;
      margin-top: 16px;
      margin-bottom: 20px;
      align-items: flex-start !important;
      .zh_goods_img{
        width: 144px;
        height: 144px;
        border-radius: 8px;
        overflow: hidden;
        .zh_pic{
          width: 100%;
          height: 100%;
        }
      }
      .zh_goods{
        width: 624px;
        color: $fsColor;
        .zh_goods_name{
          margin:0 0 24px 0;
          line-height: 1;
          font-size: 22px;
          .zh_line{
            display: inline-block;
            margin: 0 6px;
            font-size: 18px;
          }
          .zh_unit{
            font-size: 18px;
          }
        }
        .zh_goods_item{
          margin-bottom: 18px;
          .zh_goods_item_l{
            display: flex;
            align-items: center;
            flex: 1;
            flex-wrap: nowrap;
            line-height: 1.3;
            >span{
              display: inline-block;
            }
            .zh_goods_title{
              color: #737480;
            }
            .zh_goods_text{
              @include textOverflow(200px);
              .unit{
                display: inline-block;
                margin-right:2px;
              }
            }
          }
        }
      }
    }
    .zh_introduce{
      margin-top: 0;
      flex-wrap: wrap;
      padding-top: 16px;
      height: auto;
      .item{
        width:max-content;
        margin-right: 40px;
        margin-bottom: 16px;
      }
    }
    .tabList{
    margin-top: 16px;
    height:430px;
    padding-bottom: 16px;
    box-sizing: border-box;
    .name{
      color: #73737F;
    }
    .text2{
      color: $fsColor;
    }
  }

  }
}

</style>
