<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
    v-bind="$attrs"
    width="920"
    class="goodInfo"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeDia"
    destroy-on-close
    align-center
    :title="title"
    @open="openDia"
  >
      <el-scrollbar class="abcCheck_info" :style="{ height :`calc( ${height}px - 300px)`}" always>
  <div class="zh_content">
    <div class="info">
      <div class="zh_title">产品信息</div>
      <div class="zh_goods_info dp_f">
        <div class="zh_goods_img">
          <good-img :src="goodsInfo.img" class="zh_pic"></good-img>
        </div>
        <div class="zh_goods">
            <div class="zh_goods_name">{{goodsInfo.productName}} <span class="zh_line">/</span> <span class="zh_unit">{{goodsInfo.unitName}}</span></div>
            <div class="zh_goods_item dp_f">
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">产品品牌：</span>
                <span class="zh_goods_text">{{goodsInfo.brandName || '--'}}</span>
              </div>
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">规格/型号：</span>
                <span class="zh_goods_text">{{goodsInfo.modelName || '--'}}</span>
              </div>
            </div>
            <div class="zh_goods_item dp_f">
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">产品条码：</span>
                <more-list :list='goodsInfo.encoded' v-if='goodsInfo.encoded'></more-list>
                <span class="zh_goods_text" v-else>--</span>
              </div>
              <div class="zh_goods_item_l">
                <span class="zh_goods_title">合计{{
                    (title !== '临期产品' && title !== '滞销产品') ? '标签' : null
                  }}数量/金额(元)：</span>
                <span class="zh_goods_text">{{ goodsInfo.totalNum || 0 }} / <span
                    class="unit">¥</span>{{ $filters.toYuan(goodsInfo.totalPrice, '元') }}</span>
              </div>
            </div>
        </div>
      </div>
       <div class="zh_title">详细信息</div>
       <div class="tabList">
        <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
         class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{border:'none'}"
        :header-row-style="{border:'none'}"
        @row-click="showRfid"
      >
        <el-table-column
          align="center"
          label="序号"
          width="100"
        >
        <template #default='scope'>
          <div class="table_text" >{{scope.$index + 1}}</div>
        </template>
       </el-table-column>
          <el-table-column
              align="center"
              label="标签状态"
          >
            <template #default='scope'>
              <div class="error_type" >
                <div class="err_type" :class="scope.row.labelStatus == 0?'status2':''">
                  {{scope.row.labelStatusDesc}}
                </div>
              </div>
            </template>
          </el-table-column>
       <el-table-column
          align="center"
          label="标签序列号"
        >
        <template #default='scope'>
          <div class="table_text2" >{{scope.row.serial}}</div>
        </template>
       </el-table-column>
       <el-table-column
              align="center"
              v-if="showStockCount"
              label="库存数量"
          >
            <template #default='scope'>
              <div class="table_text">{{ scope.row.scatteredCount || '--' }}</div>
            </template>
          </el-table-column>
       <el-table-column
                    align="center"
                    label="货架编号"
                >
                  <template #default='scope'>
                    <div class="table_text">{{ scope.row.childShelfName || '--' }}</div>
                  </template>
                </el-table-column>
       <el-table-column
          align="center"
          :label="valueString"
        >
        <template #default='scope'>
          <div class="table_text" >{{scope.row.value}}</div>
        </template>
       </el-table-column>
        </el-table>
      </div>

    </div>
  </div>
      </el-scrollbar>
  </el-dialog>
  <rfidHistory v-model="historyShow" v-model:rfidInfo="rfidInfo" @handleClose="closeRfid"></rfidHistory>
  </div>

</template>


<script setup>
import MoreList from '@/components/moreList/moreList.vue';
import {tableRowClassName} from '@/utils/tool.js'
import {stockLabelsLIst} from '@/utils/api/rfid.js'
import {computed, getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import GoodImg from "@components/goodImg/index.vue";
import {useViewPort} from "@utils/tool/useVIewPort";

const props = defineProps({
  goodsInfo:{
    type:Object,
    default:()=>{}
  },
  alarmType:{
    type: [String, Number],
    default:''
  },
  isError:{
    type:Boolean,
    default:false
  },
  valueString:{
    type:String,
    default:""
  },
  stockId:{
    type: [String, Number],
    default:""
  },
  title: {
    type: String,
    default: ""
  },
  scatter: {
    type: Number
  },
  stockType:{
    type: [String , Number],
    default: ''
  }
})
const {goodsInfo, alarmType, scatter, valueString, stockId, title , stockType} = toRefs(props)
const { proxy } = getCurrentInstance()
const {height} = useViewPort()

//请求列表接口
const getRfidList = ()=>{
  stockLabelsLIst({
    productId:goodsInfo.value.productId,
    modelId:goodsInfo.value.modelId,
    alarmType:alarmType.value,
    stockId:stockId.value,
    scattered: getScattered.value,
    price: goodsInfo.value.price,
    childShelfId: goodsInfo.value.childShelfId,
    stockType: stockType.value
  }).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    tableData.value = res.data?.list || []
  })
}
const showStockCount = computed(() => {
  return tableData.value.findIndex(item => item.scatteredCount > 0) > -1
})

const getScattered = computed(() => {
  return scatter.value || goodsInfo.value.scattered !== undefined ? goodsInfo.value.scattered : -1
})
//弹窗打开产品列表接口
const openDia = ()=>{
  getRfidList()
}
const emit = defineEmits(['handleClose'])
const tableData = ref([])
const historyShow = ref(false)
const closeRfid =()=>{
  historyShow.value = false
}
const rfidInfo = reactive({
  labelId:'',
  rfid:"",
  serial:'',
  orderSn:goodsInfo.value.orderSn,
  productId:goodsInfo.value.productId,
  modelId:goodsInfo.value.modelId,
})
const showRfid = (row)=>{
  rfidInfo.labelId = row.labelId
  rfidInfo.rfid = row.rfid
  rfidInfo.serial = row.serial
  historyShow.value = true
}

const closeDia = (done)=>{
  emit('handleClose',{})
  done()
}


</script>
<script>
import { reactive, toRefs, ref, watch,getCurrentInstance } from "vue";
import { ArrowDown } from '@element-plus/icons-vue'
import rfidHistory from '@/components/rfidList/rfidHistory.vue'
export default {
  components: {
    ArrowDown,
    rfidHistory
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content{
    padding: 16px;
    box-sizing: border-box;
    position: relative;
    .zh_goods_info{
      border-radius: 8px;
      background-color: #FAFBFC;
      padding: 24px;
      margin-top: 16px;
      margin-bottom: 24px;
      .zh_goods_img{
        width: 144px;
        height: 144px;
        border-radius: 8px;
        overflow: hidden;
        .zh_pic{
          width: 100%;
          height: 100%;
        }
      }
      .zh_goods{
        width: 624px;
        color: $fsColor;
        .zh_goods_name{
          margin: 25px 0;
          line-height: 1;
          font-size: 22px;

          span {
            vertical-align: middle;
          }
          .zh_line{
            margin: 0 6px;
            font-size: 18px;
          }
          .zh_unit{
            font-size: 18px;
          }
        }
        .zh_goods_item{
          margin-bottom: 24px;
          .zh_goods_item_l{
            display: flex;
            align-items: center;
            flex: 1;
            flex-wrap: nowrap;
            line-height: 1.3;
            >span{
              display: inline-block;
            }
            .zh_goods_title{
              color: #737480;
            }
            .zh_goods_text{
              @include textOverflow(200px);
              .unit{
                display: inline-block;
                margin-right:2px;
              }
            }
          }
        }
      }
    }
    .zh_introduce{
      margin-top: 0;
      flex-wrap: wrap;
      padding-top: 16px;
      height: auto;
      .item{
        width:max-content;
        margin-right: 40px;
        margin-bottom: 16px;
      }
    }
    .tabList{
    margin-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    .name{
      color: #73737F;
    }
    .text2{
      color: $fsColor;
    }
  }

  }
}

</style>
